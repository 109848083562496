export const useScroll = () => {
    const scrollY = useState('scrollY');

    function updateScrollY() {
        scrollY.value = window.scrollY;
    }

    onMounted(() => window.addEventListener('scroll', updateScrollY))
    onUnmounted(() => window.removeEventListener('scroll', updateScrollY))

    return useState('scrollY');
};